﻿import Map from '@arcgis/core/Map'
import Basemap from '@arcgis/core/Basemap'
import FeatureLayerView from '@arcgis/core/views/layers/FeatureLayerView'
import FeatureFilter from '@arcgis/core/layers/support/FeatureFilter'
import MapView from '@arcgis/core/views/MapView'
import ArcGISTiledMapServiceLayer from '@arcgis/core/layers/TileLayer'
import FeatureLayer from '@arcgis/core/layers/FeatureLayer'
import FeatureTable from '@arcgis/core/widgets/FeatureTable'
import ArcGISDynamicMapServiceLayer from '@arcgis/core/layers/MapImageLayer'
import SpatialReference from '@arcgis/core/geometry/SpatialReference'
import SimpleRenderer from '@arcgis/core/renderers/SimpleRenderer'
import SimpleMarkerSymbol from '@arcgis/core/symbols/SimpleMarkerSymbol'
import PictureMarkerSymbol from '@arcgis/core/symbols/PictureMarkerSymbol'
import SimpleLineSymbol from '@arcgis/core/symbols/SimpleLineSymbol'
import Query from '@arcgis/core/rest/support/Query'
import Color from '@arcgis/core/Color'
import Graphic from '@arcgis/core/Graphic'

export function BuildMap(
    mapContainerId,
    baseMapServiceLayer,
    wireServiceLayer,
    houseServiceLayer,
    houseFeatureServiceLayer,
    cabinetFeatureServiceLayer,
    stationFeatureServiceLayer,
    postalCode,
    street,
    houseNumber,
    currentConnectionPoint,
    connectionType,

) {
    var tiled = new ArcGISTiledMapServiceLayer(baseMapServiceLayer);
    var stationOutFields = ["ASSETID", "NAMEASSET", "OBJECTID", "MIGRATIONSRCSYSTEMFEATGLOBID"];
    var cabinetOutFields = ["ASSETSINGLEFIELDSEARCH", "ASSETID", "OBJECTID", "MIGRATIONSRCSYSTEMFEATGLOBID"];

    var imageryBaseMap = new Basemap({
        baseLayers: [tiled],
    })

    const map = new Map({
        basemap: imageryBaseMap
    });
    const mapView = new MapView({
        map: map,
        container: mapContainerId // (map) Div element
    });

    let layerViews: any = {};

    mapView.spatialReference = new SpatialReference({ wkid: 102100 });

    var line = new SimpleLineSymbol();
    line.style = "short-dash-dot";
    line.color = new Color([255, 0, 197, 1]);
    line.width = 2.75;

    line = new SimpleLineSymbol();
    line.width = 2.75;


    let houseLayerHighlight: any = null;
    let highlight: any = null;


    var pfs = new PictureMarkerSymbol();
    pfs.angle = 0;
    pfs.width = 30;
    pfs.height = 30;
    pfs.url = './img/house.png';

    var marker = new SimpleMarkerSymbol();
    marker.outline = line;
    marker.size = 12;
    marker.color = new Color([168, 0, 0, 0.14]);
    marker.angle = -360;

    var nullSymbol = new SimpleMarkerSymbol();
    nullSymbol.size = 0;

    var netLayer = new ArcGISDynamicMapServiceLayer({ url: wireServiceLayer, opacity: 1 });
    var houseNumLayer = new ArcGISDynamicMapServiceLayer({ url: houseServiceLayer, opacity: 1 });
    var houseNumFeatureLayer = new FeatureLayer({ url: houseFeatureServiceLayer, outFields: ["HUSNR"] });
    var cabinetFeatureLayer = new FeatureLayer({ url: cabinetFeatureServiceLayer, outFields: cabinetOutFields });
    var stationFeatureLayer = new FeatureLayer({ url: stationFeatureServiceLayer, outFields: stationOutFields });

    houseNumFeatureLayer.renderer = new SimpleRenderer({ symbol: pfs });
    cabinetFeatureLayer.renderer = new SimpleRenderer({ symbol: nullSymbol })
    stationFeatureLayer.renderer = new SimpleRenderer({ symbol: nullSymbol })
    cabinetFeatureLayer.labelsVisible = false;
    stationFeatureLayer.labelsVisible = false;

    map.layers.push(tiled);
    map.layers.push(netLayer);
    map.layers.push(houseNumLayer);
    map.layers.push(houseNumFeatureLayer);
    map.layers.push(cabinetFeatureLayer);
    map.layers.push(stationFeatureLayer);
    mapView.whenLayerView(houseNumFeatureLayer).then(function (layerView) {
        layerViews[houseNumFeatureLayer.id] = layerView;
        showAddress(postalCode, street, houseNumber);
    });
    mapView.whenLayerView(cabinetFeatureLayer).then(function (layerView) {
        layerViews[cabinetFeatureLayer.id] = layerView;
        if (currentConnectionPoint.length > 0 && connectionType.toUpperCase() === "SKAB") {
            cabinetFeatureLayer.renderer = new SimpleRenderer({ symbol: marker })
            highlightCabinet(currentConnectionPoint);
        }
    });

    mapView.whenLayerView(stationFeatureLayer).then(function (layerView) {
        layerViews[stationFeatureLayer.id] = layerView;
        if (currentConnectionPoint.length > 0 && connectionType.toUpperCase() === "STATION") {
            stationFeatureLayer.renderer = new SimpleRenderer({ symbol: marker })
            highlightStation(currentConnectionPoint);
        }
    });

    function highlightFeatures(layer: FeatureLayer, features: Graphic[]) {
        let layerView: FeatureLayerView = layerViews[layer.id];
        let objectIds: number[] = [];
        for (var i = 0; i < features.length; i++) {
            var feature = features[i];
            objectIds.push(feature.attributes["OBJECTID"]);
            layerView.highlight(feature);
        }
        layerView.filter = new FeatureFilter({
            objectIds: objectIds
        });
    }

    async function showAddress(postalCode: string, streetName: string, houseNumber: string) {
        if (houseLayerHighlight) {
            houseLayerHighlight.remove();
        }
        var queryStr: string = "";
        try {
            var query = new Query();
            query.outSpatialReference = new SpatialReference({ wkid: 102100 });
            query.returnGeometry = true;
            query.outFields = ["HUSNR", "OBJECTID"];
            queryStr = "POSTNR = " + postalCode ;
            queryStr += " AND VEJNAVN = '" + streetName + "'";
            if (houseNumber) {
                queryStr += " AND HUSNR = '" + houseNumber + "'";
            }
            query.where = queryStr;
            houseNumFeatureLayer.visible = true;
            var result = await houseNumFeatureLayer.queryFeatures(query);
            const feature = result.features[0];
            highlightFeatures(houseNumFeatureLayer, result.features);
            if (feature) {
                mapView.goTo(
                    {
                        target: feature.geometry,
                        zoom: 19
                    },
                    {
                        duration: 0,
                        easing: "ease-in-out"
                    }
                ).catch((error) => {
                    if (error.name != "AbortError") {
                        console.error(error);
                    }
                });
            }
        } catch (e: any) {
            console.log("[Error at showAddress] Query string : " + queryStr + "; Error : " + e.message);
        }
    }

    async function highlightCabinet(assetsinglefieldsearch) {
        var queryCabinet = cabinetFeatureLayer.createQuery();
        queryCabinet.returnGeometry = true;
        queryCabinet.outFields = ["ASSETSINGLEFIELDSEARCH", "ASSETID", "OBJECTID"];
        queryCabinet.where = "ASSETSINGLEFIELDSEARCH= '" + assetsinglefieldsearch + "'";
        var ids = await cabinetFeatureLayer.queryObjectIds(queryCabinet);
        if (ids && ids.length) {
            if (highlight) {
                highlight.remove()
            }
            var layerView = layerViews[cabinetFeatureLayer.id];
            highlight = layerView.highlight(ids);
        } else if (assetsinglefieldsearch.indexOf('-') > 0) {
            queryCabinet.where = "ASSETSINGLEFIELDSEARCH= '" + assetsinglefieldsearch.split('-')[1] + "'";
            var ids = await cabinetFeatureLayer.queryObjectIds(queryCabinet);
            if (ids && ids.length) {
                if (highlight) {
                    highlight.remove()
                }
                var layerView = layerViews[cabinetFeatureLayer.id];
                highlight = layerView.highlight(ids);
            }
        }
    }

    async function highlightStation(assetid) {
        var queryStation = stationFeatureLayer.createQuery();
        queryStation.returnGeometry = true;
        queryStation.outFields = stationOutFields;
        queryStation.where = "ASSETID = '" + assetid + "'";
        var ids = await stationFeatureLayer.queryObjectIds(queryStation);
        if (highlight) {
            highlight.remove()
        }
        var layerView = layerViews[stationFeatureLayer.id];
        highlight = layerView.highlight(ids);
    }
}